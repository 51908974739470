import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './LandingPage.css';
import logoImage from './logo.jpg';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  return (
    <div className="top-bar">
      <div className="centertopbar">
        <a href="https://diabcalc.com/#header" className="logo-container">
          <img src={logoImage} alt="DiabCalc Logo" className="logolanding" />
          <span className="diabcalc-title">DiabCalc</span>
        </a>
        <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <a href="https://diabcalc.com/#details-section">
            {t('onas')}
          </a>
          <a href="https://diabcalc.com/#goals-section">
            {t('goals')}
          </a>
         
          <a href="https://diabcalc.com/#video-section">
            {t('misja')}
          </a> 
          <a href="https://diabcalc.com/#moderation-section">
            {t('becomeModeratorTitle')}
          </a>
          <a href="https://diabcalc.com/#about-section">
            {t('team')}
          </a>
          <a href="https://diabcalc.com/#media-section">
            {t('media')}
          </a>
          <a href="https://diabcalc.com/#partners-section">
            {t('partnerzy')}
          </a>
          <a href="https://diabcalc.com/#contact">
            {t('contact')}
          </a>
        </div>
        <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          &#9776;
        </div>
      </div>
    </div>
  );
};

export default NavBar;
