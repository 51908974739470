// VideoCard.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import imageforvideo from './files/gif.gif';
import './VideoCard.css';
import videoFile from './files/amimation.mp4';
import { useEffect } from 'react';
const VideoCard = () => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.useRef(null);

  const handleVideoClick = () => {
    if (!isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // Automatyczne odtwarzanie wideo po załadowaniu komponentu
    const handleVideoClick = () => {
      if (!isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    };
  }, []);

  return (
    <section className="details-vc-container fade-in">
        <div className="dt-vc-text-section">
        <div className="details-vc-title">
          <h1>{t('ourMissionTitle')} <span className="dc">DiabCalc</span></h1>
        </div>
        <br />
        <p className="details-vc-description">{t('ourMissionDescription')}</p>
      </div> 
      <div className="dt-vc-image-section">
      <img src={imageforvideo} className="app-dt-img" alt="Happy Family" />

      </div>
    </section>
  );
};

export default VideoCard;
