// Header.js
import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next for translations
import familyImage from './app.png'; // Image of a happy family
import diabCalcImage from './logo.jpg'; // DiabCalc image/logo
import './Details.css';
const Details = () => {
  const { t } = useTranslation();

  return (
    <section className="details-container fade-in"  >  
    <div className="dt-image-section">
        <img src={familyImage} className="app-dt-img" alt="Happy Family" />
      </div>
      <div className="dt-ld-text-section">

      <div className="details-title">
          <h1>{t('whatis')} <span className='dc'>DiabCalc</span></h1>
         
        </div>
        <br></br>
        <p className="details-landing-description">{t('details')}</p>
    
     
      </div>
    
    </section>
  );
};

export default Details;