import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getApiUrl } from './apiUrl';

const ManageUsers = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const apiUrl = getApiUrl();
  
  useEffect(() => {
    const fetchUsers = async () => {
      if (!user) {
        navigate('/app/login');
        return;
      }

      try {
        const token = user.token;
        const headers = {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        };

        // Check if user is an administrator
        const responseAdmin = await axios.get(`${apiUrl}checkAdmin/${user.userId}`, { headers });

        if (responseAdmin.status === 200 && responseAdmin.data.isAdmin) {
          setIsAdmin(true);

          // Fetch list of users as an administrator
          const responseUsers = await axios.get(`${apiUrl}users`, { headers });

          if (responseUsers.status === 200) {
            setUsers(responseUsers.data.users);
          } else {
            console.error(`Error fetching users: ${responseUsers.data.message}`);
            toast.error(t('errorFetchingUsers'));
          }
        } else {
          console.error(`Error checking admin status: ${responseAdmin.data.message}`);
          toast.error(t('notAdminRedirect'));
          navigate('/app');
        }
      } catch (error) {
        console.error(`Error in fetchUsers: ${error.message}`);
        toast.error(t('errorOccurred'));
        navigate('/app');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [user, navigate, t]);

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  if (!isAdmin) {
    return <div>{t('notAuthorized')}</div>;
  }

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='info'>
        <h1>{t('manageUsers')}</h1>
        <br />
        <table>
          <thead>
            <tr>
              <th>{t('email')}</th>
              {/* Add other table headers as needed */}
            </tr>
          </thead>
          <tbody>
            {users.map((currentUser) => (
              <tr key={currentUser.id}>
                <td>{currentUser.email}</td>
                {/* Display other user details in corresponding table cells */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    
    </div>
  );
};

export default ManageUsers;
