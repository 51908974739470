import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'; 
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext'; 
import './LoginPanel.css';
import { getApiUrl } from './apiUrl';
import IonIcon from '@reacticons/ionicons';
import { Capacitor } from '@capacitor/core';
import { SocialLogin } from '@capgo/capacitor-social-login';

const LoginPanel = () => {
  const { login } = useUser();
  const { t } = useTranslation();
  const apiUrl = getApiUrl();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const initializeGoogleLogin = async () => {
    try {
      const isMobile = Capacitor.isNativePlatform();
      const webClientId = isMobile
        ? '258496790445-c1astge0rfsptg6ijejc8s5vq2cu28fk.apps.googleusercontent.com'
        : '258496790445-9q7e3t4u33vc4o030j6edlc559d39jli.apps.googleusercontent.com';


    

      await SocialLogin.initialize({
        google: { webClientId },
      });
      console.log('Google login initialized successfully');
    } catch (error) {
      console.error('Google login initialization failed', error);
      toast.error('Google login initialization failed');
    }
  };

  const handleLoginGoogle = async () => {
    try {
      console.log('Initiating Google login');
      await SocialLogin.logout({ provider: 'google' });

      const res = await SocialLogin.login({
        provider: 'google',
        options: { scopes: ['email', 'profile'] },
      });

      if (res?.result?.accessToken) {
        console.log('Google Login Successful:', res.result);
        toast.success('Google login successful!');
        
        const response = await axios.post(`${apiUrl}auth/google`, {
          accessToken: res.result.accessToken,
        });

        if (response.data.success) {
          login(response.data.userId, response.data.token);
          navigate('/app');
        } else {
          toast.error('Google login failed: Invalid credentials.');
        }
      } else {
        console.error('Google login failed: No access token received');
        toast.error('Google login failed: No access token received.');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      toast.error(`Google login failed: ${error.message}`);
    }
  };

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error(t('enterUsernameAndPassword'));
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}login`, { username, password }, {
        withCredentials: true,
      });

      if (response.data.success) {
        toast.success(t('loginSuccess'));
        login(response.data.userId, response.data.token);
        navigate('/app');
      } else {
        toast.error(t('loginError'));
      }
    } catch (error) {
      toast.error(t('loginErrorConnection'));
    }
  };

  useEffect(() => {
    initializeGoogleLogin();
  }, []);

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('login')}</h1><hr />
        <label>{t('email')}: <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /></label>
        <label>{t('password')}: <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></label>
        <button onClick={handleLogin}>{t('login')}</button>

        {/* <p>{t('orlogin')}</p>

        <button onClick={handleLoginGoogle} className="google-login-button">
          <IonIcon size="large" name="logo-google" />
          {t('logingoogle')}
        </button> */}

        <hr />
        <p>{t('noAccount')} <Link className="change" to="/app/register">{t('registerHere')}</Link>.</p>
      </div>
    </div>
  );
};

export default LoginPanel;
