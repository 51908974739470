import React, { useState, useEffect, useCallback } from 'react';
import { getApiUrl } from './apiUrl';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useUser } from './UserContext';
import './ListView.css';
import { Link, useNavigate } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';
import { Capacitor } from '@capacitor/core';
import { saveMealsToDB, getMealsFromDB } from './indexedDB';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Share } from '@capacitor/share';


const ListView = () => {
  const { t } = useTranslation();
  const [meal, setMeal] = useState([]);
  const [calculatedWw, setCalculatedWw] = useState({});
  const [calculatedFpu, setCalculatedFpu] = useState({});
  const [totalWwForCalculator, setTotalWwForCalculator] = useState(0);
  const [totalFPUForCalculator, setTotalFPUForCalculator] = useState(0);
  const { user } = useUser();
  const [value, setValue] = useState({});
  const navigate = useNavigate();
  const apiUrl = getApiUrl();
  const [isSyncing, setIsSyncing] = useState(false);
  const [error, setError] = useState(null);


  const fetchData = useCallback(async () => {
    try {
      if (!user) {
        console.error('User not available. Redirecting to login...');
        navigate('/app/login');
        return;
      }

      setIsSyncing(true);
      setError(null); // Reset error state

      if (navigator.onLine) {
        const token = user.token;
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        const userId = user.userId;
        const response = await axios.get(`${apiUrl}getProductsInList/${userId}`, { headers });

        if (response.data.success) {
          const mealData = response.data.mealProducts || [];
          setMeal(mealData);
          if (Capacitor.isNativePlatform()) {
            await saveMealsToDB(userId, mealData);
          }
        } else {
          throw new Error('Server responded with an error.');
        }
      } else {
        if (Capacitor.isNativePlatform()) {
          const offlineData = await getMealsFromDB(user.userId);
          if (offlineData) {
            setMeal(offlineData.meals || []);
          } else {
            console.log('No offline data available');
          }
        }
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
      setError(t('fetchError'));
    } finally {
      setIsSyncing(false);
    }
  }, [user, navigate, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const calculatePerServing = (per100g, servingSize) => {
    const servingSizeInGrams = parseFloat(servingSize);
    if (!isNaN(servingSizeInGrams) && per100g !== null) {
      return (per100g * servingSizeInGrams) / 100;
    }
    return null;
  };

  const calculate = useCallback((productId, grams) => {
    if (!isNaN(grams) && grams >= 0) {
      const product = meal.find((product) => product.Id === productId);
      const nutriments = JSON.parse(product.json).nutriments;
      const carbohydrates_100g = nutriments?.carbohydrates_100g || 0;
      const proteins_100g = nutriments?.proteins_100g || 0;
      const fats_100g = nutriments?.fats_100g || 0;

      const ww = calculateCU(calculatePerServing(carbohydrates_100g, grams));
      const fpu = calculateFPU(calculatePerServing(proteins_100g, grams), calculatePerServing(fats_100g, grams));

      updateCalculatedFpu(productId, fpu);
      updateCalculatedWw(productId, ww);
    } else {
      setCalculatedWw((prevWw) => ({ ...prevWw, [productId]: null }));
      setCalculatedFpu((prevFpu) => ({ ...prevFpu, [productId]: null }));
    }
  }, [meal]);

  const updateCalculatedWw = (productId, ww) => {
    setCalculatedWw((prevWw) => {
      const updatedWw = { ...prevWw, [productId]: ww ?? "0.0" };
      const totalWw = Object.values(updatedWw).reduce(
        (acc, value) => acc + (parseFloat(value) || 0),
        0
      );
      setTotalWwForCalculator(parseFloat(totalWw.toFixed(1)));
      return updatedWw;
    });
  };

  const updateCalculatedFpu = (productId, fpu) => {
    setCalculatedFpu((prevFpu) => {
      const updatedFpu = { ...prevFpu, [productId]: fpu };
      const totalFpu = Object.values(updatedFpu).reduce(
        (acc, value) => acc + (parseFloat(value) || 0),
        0
      );
      setTotalFPUForCalculator(parseFloat(totalFpu.toFixed(1)));
      return updatedFpu;
    });
  };

  const calculateFPU = (protein, fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateTotalCU_100g = () => {
    const totalCU = meal.reduce(
      (acc, product) => acc + calculateCU(JSON.parse(product.json).nutriments.carbohydrates_100g),
      0
    );
    return parseFloat(totalCU.toFixed(1));
  };

  const calculateTotalFPU_100g = () => {
    const totalFPU = meal.reduce(
      (acc, product) => acc + calculateFPU(JSON.parse(product.json).nutriments.proteins_100g, JSON.parse(product.json).nutriments.fats_100g),
      0
    );
    return parseFloat(totalFPU).toFixed(1);
  };

  const calculateCarbsPer100g = (product) => {
    try {
      const nutriments = JSON.parse(product.json).nutriments;
      if (nutriments && nutriments.carbohydrates_100g !== undefined) {
        return calculateCU(nutriments.carbohydrates_100g);
      } else {
        return t('noInformation');
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return t('dataError');
    }
  };

  const calculateFPUPer100g = (product) => {
    try {
      const nutriments = JSON.parse(product.json).nutriments;
      if (nutriments && nutriments.proteins_100g !== undefined && nutriments.fats_100g !== undefined) {
        return calculateFPU(nutriments.proteins_100g, nutriments.fats_100g);
      } else {
        return t('noInformation');
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return t('dataError');
    }
  };

  const removeFromMeal = async (productId) => {
    try {
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.delete(`${apiUrl}removeProductFromList/${productId}`, { headers });

      if (!response.data.success) {
        console.error(`Server error: ${response.status} - ${response.statusText}`);
        return;
      }

      const updatedMeal = meal.filter((product) => product.Id !== productId);
      setMeal(updatedMeal);

      const totalWw = Object.values(calculatedWw).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
      setTotalWwForCalculator(parseFloat(totalWw.toFixed(1)));

      const totalFpu = Object.values(calculatedFpu).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
      setTotalFPUForCalculator(parseFloat(totalFpu.toFixed(1)));

    } catch (error) {
      console.error('Error removing product from meal:', error);
    }
  };

  const shareProduct = async (productId) => {
    try {
      const productLink = `https://diabcalc.com/product/${productId}`;
      const productName = meal.find((product) => product.Id === productId)?.product_name || 'Produkt';

      // Sprawdzenie, czy API udostępniania jest dostępne
      if (Capacitor.isNativePlatform()) {
        // Użyj mobilnej funkcji udostępniania
        try {
          await Share.share({
            title: productName,
            url: productLink,
          });
          toast.info(`${t('sharedProductInfo')} ${productLink}`);
        } catch (error) {
          console.error('Błąd podczas udostępniania:', error);
        }
      } else if (navigator.share) {
        await navigator.share({
          title: productName,
          url: productLink,
        });
        toast.info(`${t('sharedProductInfo')} ${productLink}`);
      } else {
        // Jeśli API udostępniania nie jest dostępne, skopiuj link do schowka
        await navigator.clipboard.writeText(productLink);
      }
      toast.info(`${t('sharedProductInfo')} ${productLink}`);
    } catch (error) {
      console.error('Błąd podczas udostępniania produktu:', error);
    }
  };

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className="modal">
        <h2 className="meal-title">{t('ListTitle')}</h2>
        {isSyncing && (
          <div className="syncing-indicator">
            <IonIcon name="sync" className="sync-icon" size={24} spin={isSyncing.toString()} />
            <span>{t('syncing')}</span>
          </div>
        )}
        {error && <p className="error-message">{error}</p>}
   
        <p className="meal-total-cu">
          <IonIcon name='flash' className='text' size={20} /> {t('totalCU')} {calculateTotalCU_100g()}
        </p>
        <p className="meal-total-cu">
          <IonIcon name='flash-outline' className='text' size={20} /> {t('totalFPU100')} {calculateTotalFPU_100g()}
        </p>
        <p className="total-ww-for-calculator">
          <IonIcon name='flash' className='text' size={20} /> {t('totalWW')} {totalWwForCalculator}
        </p>
        <p className="total-ww-for-calculator">
          <IonIcon name='flash-outline' className='text' size={20} /> {t('totalFPU')} {totalFPUForCalculator}
        </p>
        <div className="meal-products">
          {meal.map((product) => (
            <div key={product.Id} className="meal-product">
              <h3 className="product-name">{JSON.parse(product.json).product_name}</h3>
              <p className="product-cu">
                <IonIcon name='flash' className='text' size={20} /> {t('carbsExPer100g')}: {calculateCarbsPer100g(product)}
              </p>
              <p className="product-cu">
                <IonIcon name='flash-outline' className='text' size={20} /> {t('FPUPer100g')}: {calculateFPUPer100g(product)}
              </p>
              <div className="calculator">
                <h3>{t('calculatorTitle')}</h3>
                <label>
                  {t('gramInputLabel')}
                  <input
                    type="number"
                    value={value[product.Id] || ''}
                    onChange={(e) => setValue({ ...value, [product.Id]: e.target.value })}
                  />
                </label>
                <button onClick={() => calculate(product.Id, parseFloat(value[product.Id]))}>
                  {t('calculateWWBtn')}
                </button>
                {calculatedWw[product.Id] !== null && (
                  <p>
                    <IonIcon name='flash' className='text' size={20} /> {t('calculatedWWLabel')} {calculatedWw[product.Id]}
                  </p>
                )}
                {calculatedFpu[product.Id] !== null && (
                  <p>
                    <IonIcon name='flash-outline' className='text' size={20} /> {t('calculatedFPULabel')} {calculatedFpu[product.Id]}
                  </p>
                )}
              </div>

              {navigator.onLine && (
                <button className="product-remove-btn" onClick={() => removeFromMeal(product.Id)}>
                  {t('removeFromMealBtn')}
                </button>
              )}
              <button className="product-share-btn" onClick={() => shareProduct(product.Id)}>
              <IonIcon name='share-outline' className='text' size={20} /> 
              </button>
            </div>
          ))}
        </div>
        <div className="social-media">
          <Link className="search-link" to="/app/search">
            <IonIcon name="search" />
          </Link>
          <Link className="search-link" to="/app/scanner">
            <IonIcon name="scan" />
          </Link>
          <Link className="search-link" to="/app/calc">
            <IonIcon name="calculator" />
          </Link>
        </div>
      </div>
   
    </div>
  );
};

export default ListView;