import React from 'react';
import './Policy.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from './NavBar';
import Footer from './Footer';

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div className='landing-page terms'>
      <NavBar />

      <div className='landinginfo'>
        <h1>{t('termsOfService')}</h1>
        <hr />

        <p className='zakladkip'>{t('termsIntro')}</p>

        <ol>
          <li>
            <strong>{t('informationSources')}:</strong> {t('informationSourcesDetails')}
          </li>
          <li>
            <strong>{t('dataUpdate')}:</strong> {t('dataUpdateDetails')}
          </li>
          <li>
            <strong>{t('userAddedProducts')}:</strong> {t('userAddedProductsDetails')}
          </li>
          <li>
            <strong>{t('userResponsibility')}:</strong> {t('userResponsibilityDetails')}
          </li>
          <li>
            <strong>{t('medicalAdvice')}:</strong> {t('medicalAdviceDetails')}
          </li>
          <li>
            <strong>{t('errorReporting')}:</strong> {t('errorReportingDetails')}
          </li>
          <li>
            <strong>{t('appUpdates')}:</strong> {t('appUpdatesDetails')}
          </li>
          <li>
            <strong>{t('userPrivacy')}:</strong> {t('userPrivacyDetails')}
          </li>
          <li>
            <strong>{t('termsChanges')}:</strong> {t('termsChangesDetails')}
          </li>
          <li>
            <strong>{t('validityScope')}:</strong> {t('validityScopeDetails')}
          </li>
          <li>
            <strong>{t('contact')}:</strong> <a href="mailto:diabcalcapp@gmail.com">diabcalcapp@gmail.com</a>
          </li>
        </ol>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfService;
