import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AddProductMealPopup.css';
import GramsInputPopupFromS from './GramsInputPopupFromS';
import { getApiUrl } from './apiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddMealPPopup = ({ isVisible, onClose, addProductToMeal, product, user }) => {
  const { t } = useTranslation();
  const [showGramsPopup, setShowGramsPopup] = useState(false);
  const [currentProduct, setCurrentProduct] = useState('');
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [meals, setMeals] = useState([]);
  const apiUrl = getApiUrl();

  useEffect(() => {
    if (isVisible) {
      setCurrentProduct(product);
      fetchMeals();
    }
  }, [isVisible, product]);

  // Function to fetch meals for the logged-in user
  const fetchMeals = async () => {
    try {
      const response = await axios.get(`${apiUrl}meals/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.data.success) {
        setMeals(response.data.meals);
      } else {
        console.error('Failed to fetch meals:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while fetching meals:', error);
    }
  };

  const handleAddProductToMeal = async (grams) => {
    try {
      if (!selectedMeal) {
        console.error('No meal selected');
        return;
      }

      const productNutrients = {
        carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates,
        proteins_100g: product.nutriments?.proteins || product.protein_per100 || product.nutriments?.proteins_100g,
        fats_100g: product.nutriments?.fat || product.fat_per100 || product.nutriments?.fats_100g,
      };

      const json = JSON.stringify({
        product_name: currentProduct || product?.name || "Unknown Product",
        nutriments: productNutrients || {}
      });
      
      const response = await axios.post(`${apiUrl}addProductMeal`, {
        userid: parseInt(user.userId, 10),
        mealid: parseInt(selectedMeal.id, 10),
        json: json,
        grams: parseInt(grams, 10),
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.data.success) {
        console.log(`Added ${currentProduct} to the meal`);
       
        addProductToMeal(currentProduct, grams);
        setShowGramsPopup(false);
        toast.success(t("Dodano do posiłku " + currentProduct || product?.name ));
        onClose();
      } else {
        console.error('Failed to add product to the meal:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while adding product to the meal:', error);
    }
  };

  const openGramsPopup = () => {
    setCurrentProduct(product.product_name);
    setShowGramsPopup(true);
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay pop-up">
      <div className="popup-container">
        <div className="popup-header">
          <h2>{t('addProduct.title')}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <div className="popup-body">
          <div>
            <h3>{t('selectMeal.title')}</h3>
            <select 
              value={selectedMeal ? selectedMeal.id : ''} 
              onChange={(e) => setSelectedMeal(meals.find(meal => meal.id === parseInt(e.target.value)))}
            >
              <option value="">{t('selectMeal.placeholder')}</option>
              {meals.map((meal) => (
                <option key={meal.id} value={meal.id}>{meal.mealname}</option>
              ))}
            </select>
          </div>
          <button className="standard-button" onClick={openGramsPopup}>
            {t('addProduct.button')}
          </button>
        </div>
      </div>
      {showGramsPopup && (
        <GramsInputPopupFromS
          productName={currentProduct}
          onClose={() => setShowGramsPopup(false)}
          onAdd={handleAddProductToMeal}
          product={product}
        />
      )}
    </div>
  );
};

export default AddMealPPopup;
