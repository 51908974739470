import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useUser } from './UserContext';
import './MealsView.css';
import { useNavigate } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';
import { getApiUrl } from './apiUrl';
import { Link } from 'react-router-dom';
const MealsView = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();

  const apiUrl = getApiUrl();
  const [meals, setMeals] = useState([]);
  const [calculatedWw, setCalculatedWw] = useState({});
  const [totalWwForCalculator, setTotalWwForCalculator] = useState(0);
  const [showAddMealForm, setShowAddMealForm] = useState(false);
  const [newMealName, setNewMealName] = useState('');
  const [mealText, setMealText] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const userid = user.userId;
      const response = await axios.get(`${apiUrl}meals/${userid}`, { headers });

      if (response.data.success) {
        setMeals(response.data.meals || []);
      } else {
        console.error('Error in server response:', response.data);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    }
  }, [user.token, user.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchMealProducts = useCallback(async (mealId) => {
    try {
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.get(`${apiUrl}mealProducts/${mealId}/${user.userId}`, {
        headers: headers
      });

      if (response.data.success) {
        return response.data.products || [];
      } else {
        console.error('Error in server response:', response.data);
        return [];
      }
    } catch (error) {
      console.error('An error occurred while fetching meal products:', error);
      return [];
    }
  }, [user.token, user.userId, apiUrl]);

  const calculateWWForMeal = useCallback(async (meal) => {
    try {
      const products = await fetchMealProducts(meal.id);
      if (products.length === 0) {
        return 0; // Jeśli brak produktów, zwracamy 0 WW
      }

      const totalCarbs = products.reduce((acc, product) => {
        const nutriments = JSON.parse(product.json).nutriments;
        const grams = product.grams || 100;
        return acc + (nutriments.carbohydrates_100g || 0) * (grams / 100);
      }, 0);

      const ww = totalCarbs / 10;
      setCalculatedWw((prevWw) => ({ ...prevWw, [meal.id]: ww.toFixed(1) }));
      return ww;
    } catch (error) {
      console.error('Error calculating WW for meal:', error);
      return 0;
    }
  }, [fetchMealProducts]);

  useEffect(() => {
    const calculateAllWw = async () => {
      try {
        const wwPromises = meals.map(meal => calculateWWForMeal(meal));
        const wwValues = await Promise.all(wwPromises);
        const totalWw = wwValues.reduce((acc, ww) => acc + (parseFloat(ww) || 0), 0);
        setTotalWwForCalculator(totalWw.toFixed(1));
      } catch (error) {
        console.error('Error calculating total WW:', error);
      }
    };

    calculateAllWw();
  }, [meals, calculateWWForMeal]);

  const removeFromMeal = async (mealId, iduser) => {
    try {
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };
  
      const requestData = {
        mealId: mealId,
        iduser: iduser
      };
  

    //   //  <div className="meal-carbs">
    //   <IonIcon name='flash-outline' className='texticon' size={20} />
    //   {calculatedWw[meal.id] !== undefined ? `${t('total')} ${calculatedWw[meal.id]} WW` : ''}
    // </div> 
      const response = await axios.delete(`${apiUrl}removeMeal`, {
        headers: headers,
        data: requestData // Przekazujemy dane jako 'data'
      });
  
      if (response.data.success) {
        console.log('Posiłek został usunięty.');
  
        // Aktualizacja stanu aplikacji, np. usuwanie posiłku z listy 'meals'
        const updatedMeals = meals.filter((meal) => meal.id !== mealId);
        setMeals(updatedMeals);
  
        // Obliczenia lub inne operacje po usunięciu posiłku
        const totalWw = Object.values(calculatedWw).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setTotalWwForCalculator(parseFloat(totalWw.toFixed(1)));
        navigate('/app/meals');
      } else {
        console.error(`Server error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error('Błąd podczas usuwania posiłku:', error);
    }
  };
  
  const handleAddMeal = async (e) => {
    e.preventDefault();
    if (!newMealName) return;

    try {
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(`${apiUrl}addMeal`, { mealname: newMealName,userid:user.userId }, { headers });

      if (response.data.success) {
        const newMeal = response.data.meal;
        setMeals([...meals, newMeal]);
        setNewMealName('');
        setShowAddMealForm(false);
        navigate(`/app/meal/${newMeal.id}`);
      } else {
        console.error('Error adding meal:', response.data);
      }
    } catch (error) {
      console.error('An error occurred while adding the meal:', error);
    }
  };

  const handleGenerateMeal = async (e) => {
    e.preventDefault();
    if (!mealText) return;
    
    setIsGenerating(true);
    try {
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(`${apiUrl}generateMealFromText`, {
        text: mealText,
        userId: user.userId
      }, { headers });

      if (response.data.success) {
        const newMealId = response.data.mealId;
      
        navigate(`/app/meal/${newMealId}`);
      }
    } catch (error) {
      console.error('Błąd podczas generowania posiłku:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const confirmDeleteMeal = (mealId,iduser) => {
    if (window.confirm(t('confirmDeleteMeal'))) {
      removeFromMeal(mealId,iduser);
    }
  };

  // Dodaj komponent LoadingDots
  const LoadingDots = () => (
    <div className="loading-dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );

  return (
    <>
      <div className='panel'>
        <div className='modal'>
          <div className="header">
            <h1 className="title">{t('myMeals')}</h1>
            <Link to="/app/listview" className='add-button-list'><IonIcon className='ion' name="bookmark-outline" size={10} color="blue"></IonIcon></Link>
            <button className="add-button" onClick={() => setShowAddMealForm(true)}>+</button>
            
          </div>

        

          <div className="meal-list">
            {meals.length > 0 ? (
              meals.map((meal) => (
                <div key={meal.id} className="meal-card pop-up">
                  <div className="click" onClick={() => navigate(`/app/meal/${meal.id}`)}>
                  <div className="meal-name" onClick={() => navigate(`/app/meal/${meal.id}`)}>{meal.mealname}</div>
                  <IonIcon name='restaurant' className='searchbtn' size={50} />
                  <div className="meal-info">
                
                
                    <div className="meal-more" onClick={() => navigate(`/app/meal/${meal.id}`)}>{t('more')}</div> 
                    
                   </div>
                    <button className="delete-button" onClick={() => confirmDeleteMeal(meal.id,user.userId)}>{t('delete')}</button>
                  </div>
                
                </div>
              ))
            ) : (
              <div className="no-meals">
                {t('noMealsFound')}
              </div>
            )}
          </div>
        </div>
      </div>
      
      {showAddMealForm && (
        <div className="add-meal-modal pop-up">
          <div className="add-meal-modal-content">
            <span className="close" onClick={() => setShowAddMealForm(false)}>&times;</span>
            <h1>{t('addnewmeal')}</h1>
            <form onSubmit={handleAddMeal}>
              <input
                type="text"
                value={newMealName}
                onChange={(e) => setNewMealName(e.target.value)}
                placeholder={t('mealName')}
                required
              />
              <button type="submit">{t('addMeal')}</button>
            </form>
            
            <div className="meal-generator">
              <h2>{t('generateFromText')}</h2>
              <p><IonIcon name='hardware-chip-outline' className='text-search' /> {t('AIdisc')}</p>
              <form onSubmit={handleGenerateMeal}>
                <textarea
                  value={mealText}
                  onChange={(e) => setMealText(e.target.value)}
                  placeholder={t('enterMealDescription')}
                  rows={4}
                />
                <button type="submit" disabled={isGenerating} className="generate-button">
                  {isGenerating ? <LoadingDots /> : t('generateMeal')}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MealsView;
