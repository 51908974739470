import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import './CameraInterface.css';  // Ensure this file contains the CSS styles
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const videoConstraints = {
  width: { min: 640 },
  height: { min: 480 },
  facingMode: 'environment',
};

const CameraInterface = () => {
  const webcamRef = useRef(null);
  const [activeButton, setActiveButton] = useState(null); // Track which button is active
  const [capturedImage, setCapturedImage] = useState(null);
const {t } = useTranslation();
  // Capture image from webcam
  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setCapturedImage(imageSrc);
      // Handle the captured image based on the active button
      if (activeButton === 'scan') {
        console.log("Scan Image Captured");
        // Perform scan operation
      } else if (activeButton === 'analyze') {
        console.log("AI Analyze Image Captured");
        // Perform AI analysis
      }
    }
  }, [webcamRef, activeButton]);

  return (
    <div className="camera-interface-container">
  
    
        <div className="camera-controls">
          <Link
            className={`control-button ${activeButton === 'scan' ? 'active' : ''}`}
          to="/app/scanner"
          >
        {t('scanner')}
          </Link>
          <Link
            className={`control-button ${activeButton === 'scan' ? 'active' : ''}`}
          to="/app/analyze"
          >
          {t('AI')}
          </Link>
        </div>
       
       
      </div>
  
  );
};

export default CameraInterface;
