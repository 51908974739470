import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getApiUrl } from './apiUrl';
import './AIModifyMealPopup.css';
import IonIcon from '@reacticons/ionicons';
const LoadingDots = () => (
  <div className="loading-dots">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

const AIModifyMealPopup = ({ isVisible, onClose, meal, products, mealId, onMealUpdate, user }) => {
  const { t } = useTranslation();
  const [userRequest, setUserRequest] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = getApiUrl();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const currentProducts = products.map(product => ({
        name: JSON.parse(product.json).product_name,
        grams: product.grams,
        nutrients: JSON.parse(product.json).nutriments
      }));

      const response = await axios.post(`${apiUrl}modifyMealFromText`, {
        mealId,
        userId: user.userId,
      
        text:userRequest
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success) {
        onMealUpdate(); // Odśwież dane posiłku
        onClose();
      }
    } catch (error) {
      console.error('Błąd podczas modyfikacji posiłku:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="ai-modify-popup">
      <div className="ai-modify-content">
        <h2>{t('modifyMealWithAI')}</h2>
        <p><IonIcon name='hardware-chip-outline' className='text-search' /> {t('AIdisc')}</p>
        <form onSubmit={handleSubmit}>
          <textarea
            value={userRequest}
            onChange={(e) => setUserRequest(e.target.value)}
            placeholder={t('enterModificationRequest')}
            rows={4}
          />
          <div className="shortcut-buttons">
            <button 
              type="button" 
              onClick={() => setUserRequest("dodaj 1 WW")}
              className="shortcut-btn"
            >
              +<IonIcon name='flash'/>Dodaj 1 WW
            </button>
            <button 
              type="button" 
              onClick={() => setUserRequest("dodaj 0.5 WW")}
              className="shortcut-btn"
            >
              +<IonIcon name='flash'/> Dodaj 0.5 WW
            </button>
            <button 
              type="button" 
              onClick={() => setUserRequest("dodaj troche białka")}
              className="shortcut-btn"
            >
            +<IonIcon name='git-merge-outline'/>   Dodaj białko
            </button>
            <button 
              type="button" 
              onClick={() => setUserRequest("zbilansuj posiłek")}
              className="shortcut-btn"
            >
              <IonIcon name='restaurant-outline'/> Zbilansuj posiłek
            </button>
            <button 
              type="button" 
              onClick={() => setUserRequest("odejmij 1 WW")}
              className="shortcut-btn"
            >
              -<IonIcon name='flash'/>Odejmij 1 WW
            </button>
            <button 
              type="button" 
              onClick={() => setUserRequest("odejmij 0.5 WW")}
              className="shortcut-btn"
            >
              -<IonIcon name='flash'/>Odejmij 0.5 WW
            </button>
          </div>
          <div className="button-group">
            <button type="button" onClick={onClose} disabled={loading}>
              {t('cancel')}
            </button>
            <button type="submit" disabled={loading} className="generate-button">
              {loading ? <LoadingDots /> : t('modify')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AIModifyMealPopup; 