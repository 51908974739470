import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useUser } from './UserContext';
import IonIcon from '@reacticons/ionicons';
import { PieChart, Pie, Cell, Tooltip,LabelList } from 'recharts';
import './MealDetails.css';
import AddProductPopup from './AddProductMealPopup';
import { getApiUrl } from './apiUrl';
import './variables.css'; // Ensure this path is correct based on your project structure
import AIModifyMealPopup from './AIModifyMealPopup';

const COLORS = ['#FF8042', '#0088FE', '#00C49F'];

const MealDetails = () => {
  const { t } = useTranslation();
  const { mealid } = useParams();
  const { user } = useUser();
  const apiUrl = getApiUrl();
  
  const [meal, setMeal] = useState(null);
  const [products, setProducts] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAIModifyPopupVisible, setIsAIModifyPopupVisible] = useState(false);





  const fetchMealDetails = useCallback(async () => {
    setLoading(true);
    try {
      const token = user.token;
      const response = await axios.get(`${apiUrl}meal/${mealid}/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        setMeal(response.data.meal);
        setProducts(response.data.products);
      } else {
        console.error('Failed to fetch meal details:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    } finally {
      setLoading(false);
    }
  }, [mealid, user, apiUrl]);

  useEffect(() => {
    fetchMealDetails();
  }, [fetchMealDetails]);

  useEffect(() => {
    if (products.length > 0) {
      updateMealWW(products);
      updateMealFPU(products);
    }
  }, [products]);



  const calculateTotalWeight = (products) => {
    return products.reduce((total, product) => total + product.grams, 0);
  };

  


  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const deleteProduct = async (productId) => {
    try {
      const token = user.token;
      const response = await axios.delete(`${apiUrl}removeProduct`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          mealid,
          userid: user.userId,
          productId,
        },
      });

      if (response.data.success) {
        setProducts((prevProducts) => prevProducts.filter(product => product.id !== productId));
        updateMealWW(products);
      } else {
        console.error('Failed to delete product:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    }
  };

  const showAddProductMenu = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const showAIModifyMenu = () => {
    setIsAIModifyPopupVisible(true);
  };

  const closeAIModifyPopup = () => {
    setIsAIModifyPopupVisible(false);
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, name }) => {
    const RADIAN = Math.PI / 180;
  
    const radius = outerRadius + 10; // Adjust the distance from the center
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // Ensure x and y are valid numbers
    if (isNaN(x) || isNaN(y)) {
      return null;
    }
  
    // Get the CSS variable values
    const labelColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color').trim();

  
    return (
      <text x={x} y={y} fill="#099dff" fontSize={15} fontWeight="bold" textAnchor="center">
        {`${name}: ${value}`}
      </text>
    );
  };
  

  useEffect(() => {
    const pieData = products.map(product => {
      const nutriments = JSON.parse(product.json).nutriments;
      return {
        carbohydrates: nutriments.carbohydrates_100g * (product.grams / 100),
        proteins: nutriments.proteins_100g * (product.grams / 100),
        fats: nutriments.fats_100g * (product.grams / 100),
      };
    });

    const totalNutrients = pieData.reduce((acc, curr) => ({
      carbohydrates: acc.carbohydrates + curr.carbohydrates,
      proteins: acc.proteins + curr.proteins,
      fats: acc.fats + curr.fats,
    }), { carbohydrates: 0, proteins: 0, fats: 0 });

    const newChartData = [
      { name: 'Carbohydrates', value: totalNutrients.carbohydrates },
      { name: 'Proteins', value: totalNutrients.proteins },
      { name: 'Fats', value: totalNutrients.fats },
    ];

    setChartData(newChartData);
  }, [products]);

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  if (!meal) {
    return <div>{t('mealNotFound')}</div>;
  }
  const calculateFPU = (protein, fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const totalWeight = calculateTotalWeight(products);
  const calculateTotalWW = (products) => {
    if (!products || products.length === 0) {
      return '0.0'; // Zwróć domyślną wartość, jeśli products jest niezdefiniowane lub puste
    }
    return products.reduce((total, product) => {
      const productData = JSON.parse(product.json);
      const productWW = (productData.nutriments.carbohydrates_100g * (product.grams / 100)) / 10;
      return total + (productWW || 0); // Ensure productWW is a number
    }, 0).toFixed(1); // Use 0 as initial value
  };
  
  const updateMealWW = (products) => {
    const updatedWW = products.length > 0 ? calculateTotalWW(products) : '?';
    setMeal((prevMeal) => ({ ...prevMeal, ww: updatedWW }));
  };
  
  const calculateTotalFPU = (products) => {
    return products.reduce((total, product) => {
      const productData = JSON.parse(product.json);
      const protein = productData.nutriments.proteins_100g * (product.grams / 100);
      const fat = productData.nutriments.fats_100g * (product.grams / 100);
      const fpu = (protein * 4 + fat * 9) / 100; // Calculate FPU (considering 4 kcal/g for protein and 9 kcal/g for fat)
      return total + (fpu || 0); // Ensure fpu is a number
    }, 0).toFixed(1); // Use 0 as the initial value
  };
  
  const updateMealFPU = (products) => {
    const updatedFPU = products.length > 0 ? calculateTotalFPU(products) : '?';
    setMeal((prevMeal) => ({ ...prevMeal, fpu: updatedFPU }));
  };
  
  return (
    <div className='panel'>
      
      <div className="modal">
        <header className="meal-details-header">
          <h1 className='title'>{meal.mealname}</h1>
          <div className="header-buttons">
            <button className="edit-button" onClick={toggleEditMode}>
             <p><IonIcon name="create-outline" size={24} /> {t('edit')}</p> 
            </button>
            <button className="ai-modify-button" onClick={showAIModifyMenu}>
            <p>  <IonIcon name="analytics-outline" size={24} /> {t('modify')}</p>
            </button>
          </div>
        </header>
        
        <div className="meal-summary">
        
          <div>{t('mealWeight')}: {totalWeight} g</div> {/* Displaying total weight */}
          <div> <IonIcon name='flash' className='text' size={20} />{t('totalWW')}: {calculateTotalWW(products)} </div> {/* Displaying total CU */}
        <div>   <IonIcon name='flash-outline' className='text' size={20} /> {t('totalFPU')}: {calculateTotalFPU(products)}</div> {/* Displaying total FPU */}
        </div>
        
        <div className="meal-details-chart">
         <div className="meal-details-chart">
  <PieChart width={300} height={300}>
    <Pie
      data={chartData}
      dataKey="value"
      nameKey="name"
      cx="50%"
      cy="50%"
      innerRadius={60}
      outerRadius={100}
      fill="#8884d8"
      paddingAngle={5}
      label={renderCustomizedLabel}
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
      <LabelList content={renderCustomizedLabel} />
    </Pie>
    <Tooltip active={true} />
  </PieChart>
</div>

        </div>
        
        <section className="meal-details-products">
  <h2>{t('products')}</h2>
  {products.length > 0 ? (
    products.map((product) => {
      const nutriments = JSON.parse(product.json).nutriments; // Przypisz nutriments
      return (
        <div key={product.id} className={`product-item-dt pop-up ${editMode ? 'draggable' : ''}`}>
          <h2 className="product-name-dt">{JSON.parse(product.json).product_name}</h2>
          <table className="product-info-table">
            <tbody>
              <tr>
                <td>{t('amountOfGrams')}:</td>
                <td>{product.grams}g</td>
              </tr>
              <tr>
                <td>{t('carbs')}:</td>
                <td>{(nutriments.carbohydrates_100g * (product.grams / 100)).toFixed(1)}g</td>
              </tr>
              <tr>
                <td>{t('proteins')}:</td>
                <td>{(nutriments.proteins_100g * (product.grams / 100)).toFixed(1)}g</td>
              </tr>
              <tr>
                <td>{t('fats')}:</td>
                <td>{(nutriments.fats_100g * (product.grams / 100)).toFixed(1)}g</td>
              </tr>
              <tr>
                <td colSpan="2" className="meal-total-cu">
                  <IonIcon name='flash' className='text' size={20} /> {t('WW')} {calculateCU(nutriments.carbohydrates_100g * (product.grams / 100))}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="meal-total-cu">
                  <IonIcon name='flash-outline' className='text' size={20} /> {t('fpu')} {calculateFPU(nutriments.proteins_100g * (product.grams / 100), nutriments.fats_100g * (product.grams / 100))}
                </td>
              </tr>
            </tbody>
          </table>
          {editMode && (
            <button className="delete-product-button" onClick={() => deleteProduct(product.id)}>
              {t('delete')}
            </button>
          )}
        </div>
      );
    })
  ) : (
    <p>{t('noProducts')}</p>
  )}
</section>


        {editMode && (
          <button className="add-product-button" onClick={showAddProductMenu}>
            {t('addproductinfo')}
          </button>
        )}
       
        
      </div> <AddProductPopup isVisible={isPopupVisible} user={user} onClose={closePopup} />
      <AIModifyMealPopup 
          isVisible={isAIModifyPopupVisible} 
          onClose={closeAIModifyPopup}
          meal={meal}
          products={products}
          mealId={mealid}
          onMealUpdate={fetchMealDetails}
          user={user}
        />
    </div>
  );
};

export default MealDetails;


