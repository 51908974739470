// Media.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';
import './Details.css';

const Partners = () => {
  const { t } = useTranslation();

  return (
    <section className="media-container partners-container fade-in">
     
      <h2 className="media-title partners"> {t('partnersdesc')}</h2>
      <p className="details-vc-description">{t('partnersdc')}</p>

      <div className="media-links">

        {/* <div className="media-card">
          <Link to="https://autopay.pl/" className="media-link">
            <div className="media-image autopay"></div>
            <div className="media-text">Autopay</div>
          </Link>
        </div> */}
        <div className="media-card">
          <Link to="https://autopay.pl/" className="media-link">
            <div className="media-image google"></div>
            <div className="media-text">Google For Startups</div>
          </Link>
        </div>
        {/* <div className="media-card">
          <Link to="https://www.enea.pl/" className="media-link">
            <div className="media-image enea"></div>
            <div className="media-text">Enea</div>
          </Link>
        </div> */}
        {/* Add more media cards here if needed */}
      </div>
      <hr></hr>
    </section>
  );
};

export default Partners;