import React from 'react';
import IonIcon from '@reacticons/ionicons';

const ShortcutPopup = ({ availableShortcuts, onAddShortcut, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Wybierz skrót</h3>
        <ul>
          {availableShortcuts.map(shortcut => (
            <li key={shortcut.id} onClick={() => onAddShortcut(shortcut)}>
              <IonIcon name={shortcut.icon} style={{ marginRight: '8px' }} />
              {shortcut.label}
            </li>
          ))}
        </ul>
        <button onClick={onClose}>Zamknij</button>
      </div>
    </div>
  );
};

export default ShortcutPopup;
