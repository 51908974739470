import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './UserContext';
import { getApiUrl } from './apiUrl';
import './AdminPanel.css';
import {toast} from 'react-toastify';

const AdminPanel = () => {
  const { user } = useUser();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const apiUrl = getApiUrl();

  useEffect(() => {
    checkAdminStatus();
  }, [user]);

  const checkAdminStatus = async () => {
    if (!user || !user.token || !user.userId) {
      setIsAdmin(false);
      setLoading(false);
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.get(`${apiUrl}checkAdmin/${user.userId}`, {
        headers: headers,
      });

      if (response.status === 200) {
        setIsAdmin(response.data.isAdmin);
        if (response.data.isAdmin) {
          fetchApplications();
        }
      } else {
        console.error(`Error checking admin status: ${response.data.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.error(`Error checking admin status: ${error.message}`);
      setLoading(false);
    }
  };

  const fetchApplications = async () => {
    if (!user || !user.token) {
      setLoading(false);
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.get(`${apiUrl}getModeratorApplications`, { headers });
      
      if (response.data.success) {
        setApplications(response.data.result);
      } else {
        console.error("Failed to fetch applications:", response.data.message);
      }
    } catch (error) {
   
      console.error("Error fetching applications:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const assignModerator = async (email) => {
    if (!email) return;

    try {
      const headers = {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`${apiUrl}assignModerator`, { email }, { headers });
      
      if (response.status === 200) {
        alert("User assigned as moderator successfully!");
        fetchApplications();
      } else {
        toast.error( response.data.message);
        console.error("Failed to assign moderator:", response.data.message);
      }
    } catch (error) {
      console.error("Error assigning moderator:", error.message);
    }
  };

  if (!isAdmin) {
    return <p>Access denied. Admins only.</p>;
  }

  return (
    <div className="admin-panel">
      <h2>Admin Panel</h2>
      {loading ? (
        <p>Loading applications...</p>
      ) : (
        <div className="applications-list">
          {applications.length === 0 ? (
            <p>No applications found.</p>
          ) : (
            applications.map(app => (
              <div className="application-card" key={app.id}>
                <p><strong>ID:</strong> {app.id}</p>
                <p><strong>Full Name:</strong> {app.full_name}</p>
                <p><strong>Email:</strong> {app.email}</p>
                <p><strong>Experience with DiabCalc:</strong> {app.experience_with_diabcalc}</p>
                <p><strong>Availability:</strong> {app.availability}</p>
                <p><strong>Motivation:</strong> {app.motivation}</p>
                <p><strong>Moderation Experience:</strong> {app.moderation_experience}</p>
                <p><strong>Experience Description:</strong> {app.experience_description}</p>
                <p><strong>Created At:</strong> {new Date(app.created_at).toLocaleString()}</p>
                <p><strong>Updated At:</strong> {new Date(app.updated_at).toLocaleString()}</p>
                <p><strong>Pending:</strong> {app.pending ? 'Yes' : 'No'}</p>
                
                <button className="assign-btn" onClick={() => assignModerator(app.email)}>
                  Assign as Moderator
                </button>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
