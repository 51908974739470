import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from './apiUrl';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [energyPer100, setEnergyPer100] = useState('');
  const [fatPer100, setFatPer100] = useState('');
  const [carbsPer100, setCarbsPer100] = useState('');
  const [fiberPer100, setFiberPer100] = useState('');
  const [proteinPer100, setProteinPer100] = useState('');
  const [portion, setPortion] = useState('');
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isGlutenFree, setIsGlutenFree] = useState(false);
  const [info, setInfo] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [loading, setLoading] = useState(true);
  const apiUrl = getApiUrl();

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!user) {
        toast.error(t('notLoggedRedirect'));
        navigate('/app/login');
        return;
      }

      try {
        const token = user.token;
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        // Check admin status
        const adminResponse = await axios.get(`${apiUrl}checkAdmin/${user.userId}`, { headers });
        setIsAdmin(adminResponse.data.isAdmin);

        // Check moderator status
        const moderatorResponse = await axios.get(`${apiUrl}checkModerator/${user.userId}`, { headers });
        setIsModerator(moderatorResponse.data.isModerator);

        // Handle cases where the user is neither an admin nor a moderator
        if (!adminResponse.data.isAdmin && !moderatorResponse.data.isModerator) {
          toast.error(t('notAuthorized'));
          navigate('/app/login');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error(t('errorOccurred'));
        navigate('/app/login');
      } finally {
        setLoading(false);
      }
    };

    checkUserStatus();
  }, [user, navigate, t, apiUrl]);

 const handleAddProduct = async () => {
  try {
    const token = user.token;
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const url = `${apiUrl}addProduct`;

    // First, add the product to the database
    const response = await axios.post(url, {
      name,
      barcode,
      energy_per100: energyPer100,
      fat_per100: fatPer100,
      carbohydrates_per100: carbsPer100,
      fiber_per100: fiberPer100,
      protein_per100: proteinPer100,
      portion,
      brand,
      category,
      image_url: imageUrl,
      glutenFree: isGlutenFree,
      info
    }, { headers });

    const { success, message, product } = response.data;

    if (success) {
      toast.success(`${t('addedNewProduct')}: ${product.name} ${t('toDatabase')}`);

      // Update the product count for the user
      const updateProductCountUrl = `${apiUrl}updateProductCount/${user.userId}`;
      const updateResponse = await axios.post(updateProductCountUrl, {}, {
        headers: {
          Authorization: `Bearer ${token}` // Dodaj token w nagłówku, jeśli jest wymagany
        }
      });
  

      if (updateResponse.data.success) {
        toast.success(t('productCountUpdated'));
      } else {
        toast.error(t('errorUpdatingProductCount'));
      }

      clearForm();
    } else {
      toast.error(`${t('errorOccurred')}: ${message}`);
    }
  } catch (error) {
    console.error('Error adding product:', error);
    toast.error(t('errorSaving'));
  }
};


  const clearForm = () => {
    setName('');
    setBarcode('');
    setEnergyPer100('');
    setFatPer100('');
    setCarbsPer100('');
    setFiberPer100('');
    setProteinPer100('');
    setPortion('');
    setBrand('');
    setCategory('');
    setImageUrl('');
    setIsGlutenFree(false);
    setInfo('');
  };

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  // Allow access if the user is either an admin or a moderator
  if (!isAdmin && !isModerator) {
    return <div>{t('notAuthorized')}</div>;
  }

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('addNewProduct')}</h1>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder={t('productName')} />
        <input type="text" value={barcode} onChange={(e) => setBarcode(e.target.value)} placeholder={t('barcode')} />
        <input type="text" value={energyPer100} onChange={(e) => setEnergyPer100(e.target.value)} placeholder={t('energyPer100g')} />
        <input type="text" value={fatPer100} onChange={(e) => setFatPer100(e.target.value)} placeholder={t('fatPer100g')} />
        <input type="text" value={carbsPer100} onChange={(e) => setCarbsPer100(e.target.value)} placeholder={t('carbsPer100g')} />
        <input type="text" value={fiberPer100} onChange={(e) => setFiberPer100(e.target.value)} placeholder={t('fiberPer100g')} />
        <input type="text" value={proteinPer100} onChange={(e) => setProteinPer100(e.target.value)} placeholder={t('proteinPer100g')} />
        <input type="text" value={portion} onChange={(e) => setPortion(e.target.value)} placeholder={t('portionSize')} />
        <input type="text" value={brand} onChange={(e) => setBrand(e.target.value)} placeholder={t('brand')} />
        <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} placeholder={t('category')} />
        <input type="text" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} placeholder={t('imageUrl')} />
        <label>
          {t('glutenFree')}:
          <input
            type="checkbox"
            checked={isGlutenFree}
            onChange={(e) => setIsGlutenFree(e.target.checked)}
          />
        </label>
        <input type="text" value={info} onChange={(e) => setInfo(e.target.value)} placeholder={t('info')} />
        <button className='btnaddproduct' onClick={handleAddProduct}>
          {t('addProductAdmin')}
        </button>
      </div>
    </div>
  );
};

export default AddProduct;
