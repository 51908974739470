// Media.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';
import './Details.css';
import IonIcon from '@reacticons/ionicons';
import ImageBurzua from './files/burzua.png'
import ImageMotywacja from './files/motywacja.png'
const Influencers = () => {
  const { t } = useTranslation();

  const influencers = [
    {
      name: 'Burżua',
      description: t('descburzua'),
      imageUrl: ImageBurzua,
      followLink: 'https://www.youtube.com/@burzuua',
      platformicon:"logo-youtube",
    },
    {
      name: 'Motywacja w cukrzycy',
      description: t('descmotywacja'),
      imageUrl: ImageMotywacja,
      followLink: 'https://www.instagram.com/motywacja_w_cukrzycy/',
      platformicon:"logo-instagram"
    },
   
  ];

  return (
    <div className="influencer-section">
        <h1 className="details-vc-title">{t('influ')}</h1>
      <p className='details-vc-description'>{t('infludesc')}</p>

      {influencers.map((influencer, index) => (
        <div className="influencer-card" key={index}>
            <Link to={influencer.followLink} >
         <img className="influencer-image" src={influencer.imageUrl}  />
          <div className="influencer-info">
            <h3 className="influencer-name">{influencer.name}</h3>
            <p className="influencer-description">{influencer.description}</p>
            <a href={influencer.followLink} className="influencer-button">
              {t('zasubskrybuj')} <br></br>
              <IonIcon name={influencer.platformicon} className='iconinflu' />
            </a>
          </div></Link>
        </div>
      ))}
    </div>
  );
};

export default Influencers;
