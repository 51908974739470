import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AboutUs.css';
import NavBar from "./NavBar";
import { useTranslation } from 'react-i18next';
import KacperImg from './files/Kacper.jpg';
import PawelImg from './files/Pawel.jpg';
import PatrykImg from './files/Patryk.jpg';
import WojtekImg from './files/Wojtek.jpg';
import JuliaImg from './files/Julia.jpg';
import KonradImg from './files/Konrad.jpg';
import AnastazjaImg from './files/Anastazja.jpg';
import IonIcon from "@reacticons/ionicons";
import Footer from './Footer';

const AboutUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Function to navigate to the app page
  const handleOpenWebVersion = () => {
    navigate('/app');
  };

  return (
    <section className="about fade-in">
      <div className="about-content">
        <h1 className="ourteam">{t('ourteam')}</h1>
        
        {/* Member 1 */}
        <div className="member">
          <h2>Kacper Sikora</h2>
          <img className="imgks" src={KacperImg} alt="Kacper Sikora" />
          <p>{t('ksopis')}</p>
          <div className="social-media">
            <Link to="https://www.youtube.com/channel/UC37t0k39bcWRzAreu7bAkSQ" className="social-link">
              <IonIcon name="logo-youtube" />
            </Link>
            <Link to="https://github.com/koderhack" className="social-link">
              <IonIcon name="logo-github" />
            </Link>
            <Link to="https://www.linkedin.com/in/kacper-sikora-b63ab9260/" className="social-link">
              <IonIcon name="logo-linkedin" />
            </Link>
          </div>
        </div>

        {/* Member 2 */}
        <div className="member">
          <h2>Paweł Bystrzyński</h2>
          <img className="imgks" src={PawelImg} alt="Paweł Bystrzyński" />
          <p>{t('pbopis')}</p>
          <div className="social-media">
            <Link to="https://stacja4.ignatianum.edu.pl/audycje/magazyn-sportowy-pierwsze-miejsce/" className="audycja">
              <IonIcon name="radio" className='icon' size={50} /> {t('audition')}
            </Link>
          </div>
        </div>

        {/* Member 3 */}
        <div className="member">
          <h2>Patryk Costello</h2>
          <img className="imgks" src={PatrykImg} alt="Patryk Costello" />
          <p>{t('pcopis')}</p>
          <div className="social-media">
            <Link to="https://www.instagram.com/patryks_05/" className="social-link">
              <IonIcon name="logo-instagram" />
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=100086019592244" className="social-link">
              <IonIcon name="logo-facebook" />
            </Link>
          </div>
        </div>

        {/* Member 4 */}
        <div className="member">
          <h2>Wojtek Wielgus</h2>
          <img className="imgks" src={WojtekImg} alt="Wojtek Wielgus" />
          <p>{t('wwopis')}</p>
          <div className="social-media">
            <Link to="https://www.instagram.com/wojtic_25/" className="social-link">
              <IonIcon name="logo-instagram" />
            </Link>
          </div>
        </div>

        {/* Member 5 */}
        <div className="member">
          <h2>Julia Hazik</h2>
          <img className="imgks" src={JuliaImg} alt="Julia Hazik" />
          <p>{t('jhopis')}</p>
          <div className="social-media">
            <Link to="https://www.instagram.com/julia__haribo/" className="social-link">
              <IonIcon name="logo-instagram" />
            </Link>
            <Link to="https://www.facebook.com/dorota.kmiecik.12382" className="social-link">
              <IonIcon name="logo-facebook" />
            </Link>
          </div>
        </div>

        {/* Member 6 */}
        <div className="member">
          <h2>Anastasiia Slobodianyk</h2>
          <img className="imgks" src={AnastazjaImg} alt="Anastasiia Slobodianyk" />
          <p>{t('asopis')}</p>
          <div className="social-media">
            <Link to="https://www.instagram.com/7467_nastya/" className="social-link">
              <IonIcon name="logo-instagram" />
            </Link>
          </div>
        </div>

        {/* Member 7 */}
        <div className="member">
          <h2>Konrad Lamparski</h2>
          <img className="imgks" src={KonradImg} alt="Konrad Lamparski" />
          <p>{t('klopis')}</p>
          <div className="social-media">
            <Link to="https://www.instagram.com/lampifly/" className="social-link">
              <IonIcon name="logo-instagram" />
            </Link>
          </div>
        </div>
      </div>

   
    </section>
  );
};

export default AboutUs;
