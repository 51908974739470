import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IonIcon from "@reacticons/ionicons";
import './SearchView.css';
import _ from 'lodash';
import { getApiUrl } from './apiUrl';
import noimage from "./noimage.png";
import Loading from './Loading';
import AddMealPPopup from './AddToMealPopup';
const SearchView = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [showSearch, setShowSearch] = useState(true);
  const [page, setPage] = useState(1); // Page state for pagination
  const [hasMore, setHasMore] = useState(true); // Flag to check if more products are available
  const apiUrl = getApiUrl();
  const isFetching = useRef(false); // Ref to prevent multiple fetches at once
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Ref to manage scroll event listener
  const observer = useRef();
  const lastProductElementRef = useRef();
  const handleOpenPopup = (product) => {
    if(user){
       setSelectedProduct(product); // Ustawia aktualnie wybrany produkt
    setIsPopupVisible(true);
    }
    else{
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login')
    }
    // Otwiera pop-up
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Zamykasz pop-up
  };
  const [loading, setLoading] = useState(false); // Dodano stan ładowania
  const [serverAvailable, setServerAvailable] = useState(true); // Dodano stan dostępności serwera
  // Dodaj funkcję do sprawdzania dostępności serwera
  const checkServerAvailability = async () => {
    setLoading(true); // Ustawienie ładowania na true
    try {
      const response = await fetch(getApiUrl()); // Użyj odpowiedniego URL
      if (!response.ok) {
        throw new Error('Server not reachable');
      }
      setServerAvailable(true); // Ustawienie dostępności serwera na true
    } catch (error) {
      setError(error.message); // Ustaw błąd
      setServerAvailable(false); // Ustawienie dostępności serwera na false
    } finally {
      setLoading(false); // Ustawienie ładowania na false
    }
  };


  useEffect(() => {
    checkServerAvailability();
    const intervalId = setInterval(() => {
      checkServerAvailability();
    }, 5000); // Sprawdzenie dostępności serwera co 5 sekund

    return () => clearInterval(intervalId); // Czyszczenie interwału po odmontowaniu komponentu
  }, []);

  const fetchMoreProducts = useCallback(async () => {
    setLoading(true); // Ustawienie ładowania na true
    if (isFetching.current) return;
    isFetching.current = true;
    
    try {
      const response = await axios.get(`${apiUrl}search`, {
        params: { query: encodeURIComponent(query), page, limit: 50 },
        withCredentials: true,
      });

      const { success, products: fetchedProducts } = response.data;

      if (success) {
        setProducts(prevProducts => [...prevProducts, ...fetchedProducts]);
        setPage(prevPage => prevPage + 1);
        setHasMore(fetchedProducts.length > 0);
      } else {
        setError(response.data.message);
        setServerAvailable(false); // Ustawienie dostępności serwera na false
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      isFetching.current = false;
      setLoading(false); // Ustawienie ładowania na false
    }
  }, [query, page, apiUrl]);

  useEffect(() => {
    if (lastProductElementRef.current) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreProducts();
        }
      });
      observer.current.observe(lastProductElementRef.current);
    }
  }, [lastProductElementRef, hasMore, fetchMoreProducts]);

  useEffect(() => {
    const handleScroll = _.throttle(() => {
      if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight && hasMore && !isFetching.current) {
        fetchMoreProducts();
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMoreProducts, hasMore]);

  const searchProducts = async (searchQuery) => {
    setLoading(true); // Ustawienie ładowania na true
    try {
      setPage(1);
      setHasMore(true);
      const response = await axios.get(`${apiUrl}search`, {
        params: { query: encodeURIComponent(searchQuery), page: 1, limit: 50 },
        withCredentials: true,
      });

      const { success, products: fetchedProducts } = response.data;

      if (success) {
        setProducts(fetchedProducts);
        setShowSearch(false);
        setPage(2); // Set page to 2 for the next fetch
        setHasMore(fetchedProducts.length > 0);
        scrollToTop();
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false); // Ustawienie ładowania na false
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.product_name);
    setSuggestions([]);
    searchProducts(suggestion.product_name);
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateFPU = (protein,fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };
  const calculatePerServing = (per100g, servingSize) => {
    const servingSizeInGrams = parseFloat(servingSize);
    if (!isNaN(servingSizeInGrams) && per100g !== null) {
      return (per100g * servingSizeInGrams) / 100;
    }
    return null;
  };

  const formatNumber = (number) => {
    if (typeof number === 'number' && isFinite(number)) {
      return number.toFixed(1);
    } else {
      return t('noInformation');
    }
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);

  const scrollToTop = () => {
    
    var element = document.querySelector('.product-list'); // Wybierz swój element
    if(element !== null){
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
  };

  
  const addToMeal = async (user, product) => {
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }

    try {
      const productId = generateRandomNumber();

      const response = await axios.post(
        `${apiUrl}addProductToList`,
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: product.product_name || product.name,
            barcode: product.barcode || '',
            nutriments: {
              carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates || 0,
              proteins_100g: product.protein_per100 || product.nutriments?.proteins_100g || product.nutriments?.proteins || 0,
              fats_100g: product.fat_per100 || product.nutriments?.fats_100g || product.nutriments?.fat || 0,
            },
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          withCredentials: true,
        }
      );

      const { success, message } = response.data;

      if (success) {
        toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };
  


  return (
    <>
        {!serverAvailable ? ( // Sprawdzenie dostępności serwera
            <Loading/> // Pełnoekranowy wskaźnik ładowania
        ) : (
            <div className='panel'>
                <div className="overlay"></div>
                <div className='product-search'>
              
                    {showSearch ? (
                        <div className='search'> 
                         <h1 className='blue'>{t('searchdesc')}</h1>
                            <input 
                                type="text" 
                                value={query} 
                                onChange={handleInputChange} 
                                onKeyDown={(e) => { if (e.key === 'Enter') searchProducts(query) }} 
                                aria-label={t('search')}
                            />
                            <button onClick={() => searchProducts(query)}>{t('search')}</button>
                            {suggestions.length > 0 && (
                                <ul className='suggestions'>
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion.product_name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ) : (
                        <div className='searchclick'>
                            <button onClick={() => setShowSearch(true)}>
                                <IonIcon size='50px' name="search-outline" />
                            </button>
                        </div>
                    )}

                    {products.length === 0 ? (
                        <div className='notfound'>
                            <p>{t('noProductsFound')}</p>
                        </div>
                    ) : (
                        <div className='product-list '>
                            {products.map((product, index) => (
                                <div 
                                    key={index} 
                                    className='product-item pop-up' 
                                    ref={products.length === index + 1 ? lastProductElementRef : null}
                                >
                                    <h2 className='product-name-search'>{product.product_name || t('noname')}</h2>
                                    <img src={product.image_url || noimage} alt={product.product_name} />
                                    <div className="social-media">
                                        <button className="search-link" onClick={() => addToMeal(user, product)}>
                                            <IonIcon name="bookmark" />
                                        </button>
                                     <button className='search-link' onClick={() => handleOpenPopup(product)}>
        <IonIcon name="restaurant" />
      </button>
                                    </div>
                                
                                    
                                                                        {product.is_gluten_free !== undefined ? (
                                        product.is_gluten_free ? 
                                        <p className='text-search-info'>{t('glutenFree')} <IonIcon name='checkmark-circle-outline' className='text-search' /></p> : 
                                        <p className='text-search-info'>{t('containsGluten')} <IonIcon name='close-circle-outline' className='text-search' /></p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <p className='text-search-info'>{product.info || t('No information')}</p> 
                                    <p className='text-search-info'>
  <IonIcon name='fast-food-outline' className='text-search' />
  {t('energy')} 
  {product.nutriments?.["energy-kcal"] ? `${product.nutriments["energy-kcal"]} kcal` : '0'} / 
  {product.nutriments?.["energy-kj"] ? `${product.nutriments["energy-kj"]} kj` : '0'}
</p>
                                    <table className='table'>
                                        <thead>
                                            <tr className='bold'>
                                                <th>{t('nutritionalValue')}</th>
                                                <th>{t('per100g')}</th>
                                                <th className='table-item'>{`${t('perServing')} ${product.serving_size || ''}`}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{t('carbohydrates')}</td>
                                                <td>{formatNumber(product.nutriments.carbohydrates)}</td>
                                                <td>{formatNumber(calculatePerServing(product.nutriments.carbohydrates, product.serving_size))}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('protein')}</td>
                                                <td>{formatNumber(product.nutriments.proteins)}</td>
                                                <td>{formatNumber(calculatePerServing(product.nutriments.proteins, product.serving_size))}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('fat')}</td>
                                                <td>{formatNumber(product.nutriments.fat)}</td>
                                                <td>{formatNumber(calculatePerServing(product.nutriments.fat, product.serving_size))}</td>
                                            </tr>
                                            <tr>
                                                <td><IonIcon name='flash' className='text-search' />{t('exchange')}</td>
                                                <td>{calculateCU(product.nutriments.carbohydrates)}</td>
                                                <td>{calculateCU(calculatePerServing(product.nutriments.carbohydrates, product.serving_size))}</td>
                                            </tr>
                                            <tr>
                                                <td><IonIcon name='flash-outline' className='text-search' />{t('fpu')}</td>
                                                <td>{calculateFPU(product.nutriments.proteins, product.nutriments.fat)}</td>
                                                <td>{calculateFPU(calculatePerServing(product.nutriments.proteins, product.serving_size), calculatePerServing(product.nutriments.fat, product.serving_size))}</td>
                                            </tr>
                                         
                                        </tbody>
                                    </table>
                                </div>
                            ))} 
                            <div className="spacer"></div>
                        </div>
                    )}
                    {loading && <div className="loading">Loading...</div>} {/* Wskaźnik ładowania */}
                </div>
                <AddMealPPopup
        isVisible={isPopupVisible}
        onClose={handleClosePopup}
        addProductToMeal={(product, grams) => {
          // Funkcja do dodawania produktu do posiłku
          console.log(`Dodano ${product} z ${grams} gramami`);
        }}
        user={user}
       
        product={selectedProduct} // Przekazujesz aktualnie wybrany produkt
      />
            </div>
        )}
    </>
  );
}

export default SearchView;